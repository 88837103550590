import axios from "axios";
import { ServerUrl } from "../Constant";


//Get de tous les éléments de menus
// Récupérer les menus dynamiquement depuis Voyager
export const getMenus = async (menuSlug, language = "fr") => {
  try {
    const response = await axios.get(`${ServerUrl}/api/menus/${menuSlug}/${language}`);
    return response.data; // Retourne directement la structure JSON correcte
  } catch (error) {
    console.error("Erreur de récupération des menus :", error);
    throw error.response?.data?.message || "Erreur lors du chargement des menus.";
  }
};

export const getSections = async (name, language) => {
  try {
    const response = await axios.get(`${ServerUrl}/api/sections`, {
      params: {
        // Utilisez `params` pour envoyer les paramètres de requête
        name, // Envoyer le nom comme paramètre de requête
        language, // Envoyer la langue comme paramètre de requête
      },
    });
    console.log(response);
    return response.data; // Retourne les données reçues du backend
  } catch (error) {
    console.error("Erreur lors de la récupération des sections :", error);
    throw error; // Propager l'erreur pour la gestion externe
  }
};

export const getCategoriesAndContentNumber = async (number, language) => {
  try {
    const response = await axios.get(`${ServerUrl}/api/categories`, {
      params: {
        language,
        number
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des sections :", error);
    throw error;
  }
};

export const getCategoriesAndContent = async (category, language) => {
  try {
    const response = await axios.get(`${ServerUrl}/api/categories`, {
      params: {
        language,
        category
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des sections :", error);
    throw error;
  }
};

export const getCategories = async (language) => {
    try {
      const response = await axios.get(`${ServerUrl}/api/categories`, {
        params: {
          language
        },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des categories :", error);
      throw error;
    }
  };

  export const getArticles = async (limit = 6, language = "en") => {
    try {
      const response = await axios.get(`${ServerUrl}/api/articles`, {
        params: { limit, language },
      });
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des articles :", error);
      throw error;
    }
  };

  export const getArticleById = async (id, langue = "en") => {
    const language = langue === "en" ? 2 : 1;
    try {
      const response = await axios.get(`${ServerUrl}/api/articles/${id}`, {
        params: { language },
      });
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération de l'article :", error);
      throw error;
    }
  };

