import React, { useState, useEffect } from "react";
import { getMenus } from "../../api/contentApi"; // Importation de l'API
import logo from "../../assets/img/logo.jpg";
import { Link } from "react-router-dom";

export default function Header({ language = "fr", transparent }) {
  const [menuData, setMenuData] = useState([]);
  const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const menus = await getMenus("site", language);
        setMenuData(menus);
      } catch (error) {
        console.error("Erreur de chargement du menu :", error);
      }
    };
    fetchMenus();
  }, [language]);


  

  return (
    <nav className={`${transparent ? "top-0 absolute z-50 w-full" : "relative shadow-lg bg-white"} flex flex-wrap items-center justify-between px-2 py-3`}>
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className={`${transparent ? "text-white" : "text-gray-800"} text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase`}
            href="http://www.ascnpd.cm"
          >
            <img src={logo} className="h-24 w-24" alt="logo" />
          </a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i className={`${transparent ? "text-white" : "text-gray-800"} fas fa-bars`}></i>
          </button>
        </div>

        <div className={`lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none${navbarOpen ? " block rounded shadow-lg" : " hidden"}`} id="example-navbar-warning">
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            {menuData.map((menu) => (
              <li key={menu.id} className="flex items-center relative group">
                <a
                  class="text-gray-800 hover:text-gray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                >
                  <span class="inline-block ml-2">{menu.title}</span>
                </a>
                {menu.children.length > 0 && (
                  <ul className="dropdown-menu absolute hidden group-hover:block bg-white text-gray-700 pt-1"  style={{
                    position: "absolute",
                    top: "25px",
                    width: "200px",
                
                    zIndex: "1000",
                    backgroundColor: "white",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Ombre légère
                    
                  }}>
                    {menu.children.map((child) => (
                      <li key={child.id}>
                        <Link to={`/${child.url}/${child.element_id}`} className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200">
                          {child.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}

            {/* Bouton Connexion */}
            <li className="flex items-center">
              <Link to="/login">
                <button
                  className={`${transparent ? "bg-white text-gray-800 active:bg-gray-100" : "bg-pink-500 text-white active:bg-pink-600"} text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3`}
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <i className="fas fa-arrow-alt-circle-down"></i> Connexion
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
