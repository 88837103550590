import { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AnonymousLayout from "../layouts/AnonymousLayout";
import AdminLayout from "../layouts/AdminLayout";
import FullScreenLayout from "../layouts/FullScreenLayout.js";

import HomePage from "../pages/HomePage/HomePage";
import AboutPage from "../pages/AboutPage/AboutPage";
import LoginPage from "../pages/LoginPage"; 
import RegisterPage from "../pages/RegisterPage";
import CreditPage from "../pages/CreditPage.js";
import AppelePage from "../pages/AppelePage.js";
import UserListPage from "../pages/UserListPage.js";
import ArticlePage from "../pages/Article/ArticlePage.js";

import TrainingAddPage from "../pages/Training/TrainingAddPage.js"
import TrainingDetailsPage from "../pages/Training/TrainingDetailsPage.js"
import TrainingListPage from "../pages/Training/TrainingListPage.js"

import Profile from "../adminpage/Profile.js";

import Page404 from "../pages/Page404";

import Dashboard from "../adminpage/Dashboard.js";
import AjoutDepartement from "../components/departement/AjoutDepartement.js";
import AjoutVille from "../components/ville/AjoutVille.js";

export default function Router() {
  const [isUserConnected, setIsUserConnected] = useState(true);

  return (
    <Routes>
      { <Route path="/dashboard" element={<AdminLayout />}>
        <Route index element={<Navigate to="/dashboard/welcome" />} />
        <Route path="welcome" element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="users" element={<UserListPage />} />
        <Route path="departement/add" element={<AjoutDepartement />} />
        <Route path="ville/add" element={<AjoutVille />} />
        <Route path="training/add" element={<TrainingAddPage />} />
        <Route path="training/details/:id" element={<TrainingDetailsPage />} />
        <Route path="training/list" element={<TrainingListPage />} />
        

      </Route> }
      <Route element={<AnonymousLayout />}>
        <Route index element={<Navigate to="/home" />} />
        <Route path="/credits" element={<CreditPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/deviensappele" element={<AppelePage />} />
        <Route path="/article/:id" element={<ArticlePage />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />  
        
      </Route>

      <Route element={<FullScreenLayout />}>
        <Route index element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} /> 
        <Route path="/register" element={<RegisterPage />} /> 
      </Route>
    </Routes>
  );
}
