import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getArticleById } from "../../api/contentApi";

const ArticlePage = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const language = "fr"; //localStorage.getItem("language") || "en";

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await getArticleById(id, language);
        setArticle(response);
      } catch (error) {
        console.error("Erreur lors du chargement de l'article :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id, language]);

  if (loading) {
    return <p className="text-center mt-4">Chargement de l'article...</p>;
  }

  if (!article) {
    return <p className="text-center mt-4">Article introuvable.</p>;
  }

  return (
    <main className="container mx-auto px-2 py-4">
      <section className="max-w-8xl mx-auto bg-white shadow-md rounded-lg p-4">
        {/* Titre */}
        <h1 className="text-2xl font-semibold mb-3 text-center">{article.title}</h1>

        {/* Image avec gestion des erreurs */}
        {article.image ? (
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-auto rounded-md mb-3"
          />
        ) : (
          <p className="text-center text-gray-500">Aucune image disponible</p>
        )}

        {/* Informations de l'article */}
        <div className="mb-0">
          
          <p className="text-gray-600">
            <strong>Date :</strong> {article.date}
          </p>
          <p className="text-gray-600">
            <strong>Catégorie :</strong> {article.category}
          </p>
        </div>


        <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: article.content }} />

      </section>
    </main>
  );
};

export default ArticlePage;
